.sessionsPomodoro__MainContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  .buttonSession {
    background: linear-gradient(0deg, #009fab 0%, #009fab 100%);
    color: #ffffff;
    font-size: 32px;
    height: 62px;
    width: 62px;
    border-radius: 100%;
    border: none;
  }
  .buttonSessionSelected {
    background: red;
  }
}
