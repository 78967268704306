.modal-content .modal__ModalAlertMessage {
  background: linear-gradient(0deg, #009fab, #009fab);
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  border: 0px !important;
  padding: 40px;
  margin-top: 25%;
  position: absolute;
}

.modal-content .modal__ModalAlertMessage p {
  font-family: Dosis;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
}
