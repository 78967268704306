.externalContainer__formPomodoro {
  width: 80%;
  input {
    border: none;
    background: none;
    border-bottom: 2px solid #000000;
    text-align: center;
    font-family: Dosis;
    font-size: 24px;
    font-style: normal;
    line-height: 20px;
    color: #a6a6a6;
  }
  input:focus {
    border: none;
    background: none;
    border-bottom: 2px solid #000000;
  }
  .container__formPomodoro {
    border-radius: 17px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 12px 12px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 47px 29px;
    display: flex;
    flex-direction: column;
    .container__filesFormPomodoro {
      width: 100%;
      display: grid;
      grid-template-columns: 30% 70%;
      gap: 50px;
      .container__firstFieldsFormPomodoro {
        width: 100%;
        button {
          width: 100%;
          border-radius: 10px;
          background: rgba(38, 79, 128, 0.85);
          box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.15);
          color: #ffffff;
          margin: 20px 0px 10px;
          display: flex;
          justify-content: space-between;
          p {
            margin: 0;
          }
          padding: 17px 25px;
        }
      }
      .container__optionsFormPomodoro {
        display: flex;
        width: 90%;
        align-items: center;
      }
    }
  }
}
