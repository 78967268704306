@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.containerCarousel {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrollin: touch;
  @include media-breakpoint-down(md) {
    width: calc(100vw - 32px);
  }
}

.containerCarousel::-webkit-scrollbar {
  display: none;
  width: 0;
}

.containerControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 20px;
  button {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid #009fab;
    &:hover {
      background: #009fab;
    }
  }
  .button-active {
    background: #009fab;
  }
  @include media-breakpoint-down(md) {
    gap: 16px;
  }
}
