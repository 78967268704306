@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__QuickCalculation {
  padding: 60px 0px 60px;
  .TitleQuickCalculation {
    background: linear-gradient(0deg, #009fab 0%, #004aad 100%);
  }
  .contianer__calculo_rapido_Icon {
    cursor: pointer;
  }
  .container__Title {
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-down(md) {
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
  }
}
