@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import './node_modules/bootstrap/scss/mixins';

.BreadCrumbs__text {
  font-family: 'Montserrat bold', sans-serif;
  font-size: 130%; 
  @media (max-width: 990px) {
    font-size: 100%;
  }
}

.InteractiveContainerQuickCalculation{
  background-color: rgb(255, 255, 255);
  width: 50vw;
  height: 55vh;
  min-height: 350px;
  border: 3px solid #004aad;
  border-radius: 10px;
  text-align: center;
  overflow: auto;
  @media (max-width: 990px) {
    height: 60vh;
    width: 80vw;
  }
}

.ButtonInteractiveContainer{
  background: #00a0ab;
  border-radius: 10px;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: white;
  font-family: 'Montserrat Bold';
  cursor: pointer;
  &:hover {
    background: #153039;
  }
}