.container__FormLoginAndRegister {
  background: #ffffff;
  padding: 0 !important;
  height: 600px;
  border-radius: 20px;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 18px;
    color: #828282;
  }
  .container__buttons_register_type {
    & button {
      border-radius: 20px 20px 0px 0px;
    }
  }
  .button__TabActive {
    background: #ffffff;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    color: rgba(0, 159, 171, 1);
    border: none;
    border-radius: 0;
    width: 100%;
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
  }
  .button__TabDisable {
    background: #098896;
    color: #ffffff;
    border: none;
    border-radius: 0;
    width: 100%;
    font-family: Dosis;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
  }
  .button__Social {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: #828282;
    border: none;
    margin: 17px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    width: 100%;
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #828282;
      margin: 0px 10px;
    }
  }
  .input__formLoginAndRegister {
    border: none;
    border-bottom: 1px solid #828282;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: #828282;
  }
  .a__Links {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #098896;
    text-decoration: none;
    cursor: pointer;
  }
  .button_loginAndRegister {
    background: #009fab;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    width: 100%;
    border: none;
  }
  .p_incorrectUserOrPassword {
    color: red;
  }
}
