@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__KnowledgeSection {
  margin-bottom: 3.125rem;
  margin-top: 6.25rem;
  h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 3.125rem;
    line-height: 59px;
    color: rgba(0, 159, 171, 1);
    padding-bottom: 53px;
  }
  @include media-breakpoint-only(md) {
    .container__SubjectLetters {
      flex-wrap: nowrap;
      overflow: auto;
      padding: 10px 0px;
    }
  }
}
