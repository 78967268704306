@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__Benefits {
  margin-top: 6.25rem;
  @include media-breakpoint-down(md) {
    margin: 4.25rem;
  }
  h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 3.125rem;
    line-height: 59px;
    color: rgba(0, 159, 171, 1);
  }
  p {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    flex: none;
    order: 0;
    flex-grow: 1;
    color: rgba(166, 166, 166, 1);
  }
}

.carousel-indicators {
  bottom: -40px !important;
}
