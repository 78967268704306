.timePomodoro__MainContainer {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
  width: 100%;
  align-items: center;

  .buttonSelectTime {
    width: 80%;
    border: none;
    border-bottom: 1px solid #a6a6a6;
    background: none;
    padding: 5px;
    font-size: 24px;
  }
  .buttonSelectedTime {
    background: red;
  }
}

.timePomodoro__MainContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}
