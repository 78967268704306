.container__SubjectLetterInfo {
  position: absolute;
  left: -100%;
  top: 100px;
}

.div__SubjectLetterInfo {
  background: linear-gradient(
    180deg,
    rgba(0, 159, 171, 0.1) 0%,
    rgba(0, 159, 171, 0) 100%
  );
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.15);
  width: 1622px !important;
  height: 616px !important;
  border-radius: 25px;
  position: relative;
}

.container__SubjectLetterInfo p {
  font-family: Dosis;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #333333;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 50px 0px;
}

.container__SubjectLetterInfo h1 {
  font-family: Dosis;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 72px;
  color: rgba(0, 74, 173, 1);
}

.div__background__SubjectLetterInfo {
  width: 1622px !important;
  height: 616px !important;
  background-color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  border-radius: 25px;
}

.container__SubjectLetterInfo_image {
  width: 300px;
  height: 600px;
}
