@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__BasicInformation {
  position: relative;
  p {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 38px;
    align-self: stretch;
    margin: 1.25rem 0rem;
    color: rgba(166, 166, 166, 1);
  }
  h1 {
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    font-size: 4.375rem;
    line-height: 5.5rem;
    margin: 1.25rem 0rem;
    color: rgba(0, 159, 171, 1);
    @include media-breakpoint-down(md) {
      font-size: 3rem;
      line-height: 3.77rem;
      margin: 0rem;
    }
  }
}
