@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.TitleSection__externalContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  .TitleSection__mainContainer {
    padding: 0px 60px;
    height: 70px;
    margin: 0px -20px;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      color: #ffffff;
      text-align: center;
      /* Cuerpo - cabecera */
      font-family: Dosis;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    cursor: pointer;
  }
  .TitleSection__right {
    border-radius: 20px 0px 0px 20px;
    margin: 0px -30px 0px 0px;
  }
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}
