.CardTaskSchedule__mainContainer {
  box-shadow: 12px 12px 10px 0px rgba(0, 0, 0, 0.25);
  background: #264f80;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  padding: 35px 30px;
  gap: 30px;
  .buttonState {
    height: 33px;
    width: 33px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  p {
    margin-bottom: 0;
    color: #a6a6a6;
  }
  button {
    background: none;
    border: none;
  }
}

.CardTaskCompleted__mainContainer {
  background: #098896;
}
