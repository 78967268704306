.externalContainer__QuickCalculationPage {
  background-image: linear-gradient(to top, #004aad, #ffffff);
  // background-image: linear-gradient(to top, #ffffff, #004aad);
  height: 72vh;
  min-height: 400px; 
  .container__calculoRapidoIcon {
    position: absolute;
    left: -16vw;
    top: 40%;
    transform: translateY(-50%);
    width: 45%;
    height: 45%;
    z-index: 0;
    @media (max-width: 990px) {
      display: none;
    }
  }
  .location__InteractiveQuickCalculation{
    position: absolute;
    top: 15%;
    left: 25%;
    @media (max-width: 990px) {
      left: 50%;
      transform: translateX(-50%); 
    }
    @media (max-height: 450px) {
      margin-top: 60px;
    }
    
  }
}
