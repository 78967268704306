.CardTaskPomodoro__mainContainer {
  padding: 30px;
  background: #264f80;
  box-shadow: 12px 12px 10px 0px rgba(0, 0, 0, 0.25);
  width: 400px;
  border-radius: 15px;
  border: none;
  &:hover {
    background: #098896;
  }
  .container__Title {
    display: flex;
    justify-content: space-between;
    h6 {
      color: #ffffff;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 40px;
      font-family: Roboto;
    }
    button {
      background: none;
      border: none;
    }
  }
  p {
    font-family: Dosis;
    font-size: 24px;
    line-height: 20px;
    color: #a6a6a6;
    text-align: center;
    margin-bottom: 40px;
  }
  h4 {
    text-align: center;
    font-family: Dosis;
    font-size: 24px;
    line-height: 20px;
    color: #a6a6a6;
    text-align: center;
  }
}
