@mixin scrollbar(
  $width: 6px,
  $track-bg: rgba(61, 60, 58, 0.199),
  $thumb-border: rgb(0, 0, 0)
) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background: $track-bg;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid $thumb-border;
    border-radius: 50px;
  }
}

//*Estilo para el contenedor del nombre de la asignatura
.subjecteInput {
  margin-top: 2%;
  font-size: 18px;
  border: none;
  border-bottom: 2px solid black;
  width: 40%;
  text-align: center;
  @media (max-width: 990px) {
    font-size: 3vw;
    width: 50%;
  }
  @media (max-width: 500px) {
    font-size: 5vw;
    width: 60%;
  }
}

//*Estilo para el contenedor de la metodologia, contando el input de las notas y los botones
.interactionContainer {
  margin: 3% 0% 0% 5%;
  display: flex;
  height: 35vh;
  @media (max-width: 990px) {
    margin-top: 7%;
  }

  //*Estilo para el contenedor de los botones
  .interactionContainer__functionSelector {
    width: 35%;
    justify-content: center;
    overflow: auto;
    @include scrollbar;
    //*Estilo especifico para los botones
    .FormButtonQuickCalculation {
      overflow: auto;
      @include scrollbar;
      width: 90%;
      margin-bottom: 5%;
      font-size: 100%;
      display: flex;
      align-items: center; // Asegura que el texto esté centrado verticalmente
      justify-content: center; 
      height: 15%;
      @media (max-width: 990px) {
        font-size: 90%;
      }  
    }
  }

  //*Estilo para el contenedor de los inputs de las notas
  .interactionContainer__noteInputs {
    width: 65%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    //*Estilo para el contenedor del titulo de los inputs
    .title__note {
      color: white;
      font-weight: 300;
      background-color: #004aad;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 60%;
      height: 15%;
      border-radius: 5px;
      @media (max-width: 990px) {
        font-size: 100%;
        width: 85%;
      }
    }

    //*Estilo para los inputs de las notas
    .Inputs {
      background-color: #004bad6c;
      color: black;
      margin-top: 1%;
      margin-bottom: 3%;
      border: none;
      text-align: center;
      @media (max-width: 990px) {
        font-size: 100%;
        margin-bottom: 5%;
      }
    }
  }
}

.areaForTheInteractiveButton {
  display: flex;
  flex-direction: row;
  .submitButton {
    font-family: 'Montserrat Bold', sans-serif;
    background: #004aad;
    border: none;
    border-radius: 10px;
    width: 33%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: white;
    margin: auto;
    margin-top: 1%;
    cursor: pointer;
    &:hover {
      background: #153039;
    }
    @media (max-width: 990px) {
      width: 50%;
      font-size: 3vw;
    }
  }
}
