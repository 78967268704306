.container__ForgotPassword {
  background: #ffffff;
  max-width: 554px;
  max-height: 554px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .message {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #4f4f4f;
  }
  .Title {
    color: rgba(0, 159, 171, 1);
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
  }
  .button_loginAndRegister {
    background: #009fab;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    width: 100%;
    border: none;
  }
  .input__formLoginAndRegister {
    border: none;
    border-bottom: 1px solid #828282;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: #828282;
  }
}
