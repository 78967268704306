@mixin scrollbar(
  $width: 6px,
  $track-bg: rgba(61, 60, 58, 0.199),
  $thumb-border: rgb(0, 0, 0)
) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background: $track-bg;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid $thumb-border;
    border-radius: 50px;
  }
}
.informationHistory {
    position: relative;
    left: 7%;
    width: 50%;
    // background-color: rgba(65, 105, 225, 0.26);
    .informationHistory__notes {
      width: 100%;
      height: 60%;
      display: flex;
      padding-right: 20px;
      @include scrollbar;
      .summaryOfNotes {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        .noteNoteSumary {
          margin-left: auto;
        }
        .nameNoteSumary {
          margin-right: auto;
        }
      }
    }
    .historyDescription {
      margin-top: 7%;
      text-align: left;
    }

    .functionalities {
      margin-top: -5%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10%;
      .functionality {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .sendingbutons{
    display: flex;
    flex-direction: row;
  }

