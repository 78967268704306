@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__SubjectProfile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 50px;
  background: linear-gradient(
    180deg,
    #009fab 16.88%,
    rgba(0, 159, 171, 0) 100%
  );
  h1 {
    font-family: "Dosis";
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 88px;
    color: rgba(0, 159, 171, 1);
  }
}

.container__TitleSection {
  width: 100%;
  margin-bottom: 40px;
}

.container__imagenFondoProfile1 {
  position: absolute;
  z-index: -2;
  right: 0px;
  @include media-breakpoint-down(lg) {
    top: 21%;
  }
}

.container__imagenFondoProfile2 {
  position: absolute;
  z-index: -1;
  @include media-breakpoint-down(lg) {
    top: 5%;
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    top: 0%;
    left: 0%;
    width: 100%;
    max-height: 15px;
  }
}

.container__imagenFondoProfile3 {
  position: absolute;
  z-index: -2;
  @include media-breakpoint-down(lg) {
    top: 75%;
    width: 100%;
    height: 100%;
  }
  @include media-breakpoint-up(lg) {
    top: 50%;
    width: 100%;
    height: 100%;
  }
}

.TitleMaterias {
  background-color: #1d6eda;
}
