@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__CardInfo {
  background: rgba(196, 196, 196, 0.25);
  border-left: 5px solid #009fab;
  min-height: 100%;
  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 47px;
    margin: 1rem 0rem;
    color: rgba(0, 159, 171, 1);
  }
  .p__CardInfo {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    margin: 1rem 1rem;
    color: rgba(166, 166, 166, 1);
  }
}
