.ModalInteractiveMessage {
  background: white;
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  border: 0px !important;
  padding: 40px;
  margin-top: 25%;
  position: absolute;
}

.ModalInteractiveMessage p {
  font-family: Dosis;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: black;
}

.activebutons{
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.activebutons{
  margin-top: 10%;
}

.Button{
  border-radius: 10px;
  color: white;
  font-size: 20px;
  padding: 6px 60px;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  transition-duration: 0.4s;
}

.accept{
 background-color: #219653;
}
.reject{
  background-color: red;

}