.going-pomodoro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  &__container-actions {
    display: flex;
    gap: 20px;
  }
  h1 {
    font-size: 200px;
    color: #ffffff;
  }
  &__icon-button {
    background: none;
    border: none;
  }
  .full-screen {
    display: none;
  }
  .normal-screen {
    display: block;
  }
}

.full-screen {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(38, 79, 128, 0.85) 76.56%,
    rgba(23, 62, 107, 0) 100%
  );
  .normal-screen {
    display: none;
  }
  .full-screen {
    display: contents;
  }
}
