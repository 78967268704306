.container__footer {
  width: 100%;
  background: #333333;
  padding: 2rem 0rem;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
  }
  h5 {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
  }
  .icon__Footer {
    color: #ffffff;
    height: 1.5rem;
    width: 1.5rem;
    margin-top: 0.625rem;
  }
}
