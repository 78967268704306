.container__VideoSection {
  margin: 20px 0px 0px;
  padding: 0px;
  .TitleExtra {
    background-color: #153039;
  }
  h1 {
    font-family: "Dosis";
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 88px;
    color: #ffffff;
  }
}
