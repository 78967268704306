.container__ModalUserInfo {
  background: linear-gradient(
    90deg,
    #098896 6.37%,
    rgba(9, 136, 150, 0.5) 98.29%
  );
  border-radius: 50px 0px 0px 50px;
  padding: 5rem;
  .button__EditProfile {
    margin-top: 15px;
    padding: 15px;
    background: linear-gradient(0deg, #cfe4ff, #cfe4ff);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(0, 74, 173, 1);
    border: none;

    width: 100%;
  }
  .button__logout {
    margin-top: 15px;
    padding: 15px;
    background: #eb5757;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    border: none;
    width: 100%;
  }
  .p__name {
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 51px;
    color: #ffffff;
  }
  .hr {
    border: 1px solid #ffffff;
  }
  .p__email {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
  }
}

.modal-content {
  border-radius: 55px 0px 0px 55px !important;
  border: none !important;
}
