.container__ContainerVideo {
  box-shadow: 12px 12px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  .container__Name {
    background: #ffffff;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 25px 25px;
    padding: 42px 0px 97px 34px;
    h3 {
      font-family: "Dosis";
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 51px;
      color: rgba(0, 74, 173, 1);
    }
  }
}

.ytp-chrome-top-buttons {
  display: none !important;
}
