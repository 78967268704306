.PomodoroPage__mainContainer {
  min-height: calc(100vh - 263px);
  display: flex;
  height: 100%;
  align-items: center;
  .container__titleAndInfoPomodoro {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .TitlePomodoro {
    background-color: #264f80;
  }
  .container__CardsTasks {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .button__Go {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container__pomodoroIcon {
    position: relative;
    .container__addPomodoroIcon {
      position: absolute;
      bottom: 60px;
      right: 60px;
      button {
        background: none;
        border: none;
      }
    }
  }
  .container__addPomodoro {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.externalContainer__PomodoroPage {
  background: linear-gradient(
    180deg,
    rgba(0, 159, 171, 0.85) 0%,
    rgba(38, 79, 128, 0.59) 97.92%
  );
}
