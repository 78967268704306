@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__ApplicationInfo {
  background: linear-gradient(180deg, #009fab 0%, rgba(0, 159, 171, 0) 100%);
  h1 {
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    font-size: 4.375rem;
    line-height: 88px;
    color: #ffffff;
    margin: 231px 0px 25px 0px;
    @include media-breakpoint-down(md) {
      font-size: 2.375rem;
      line-height: 4.777rem;
      margin: 100px 0px 25px 0px;
    }
  }
  p {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    color: #333333;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 25px 0px;
  }
  .button__GooglePlay {
    padding: 30px 70px;
    background: #000000 !important;
    border-radius: 20px;
    margin: 25px 0px 236px 0px;
    @include media-breakpoint-down(md) {
      margin: 0px;
    }
    border: none;
    .icon__ApplicationInfo__GooglePlay {
      @include media-breakpoint-down(md) {
        margin: 0px;
        height: 30px;
        width: 30px;
      }
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 1.875rem;
      line-height: 35px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: #ffffff;
      margin: 0px 39px;
      @include media-breakpoint-down(md) {
        margin: 0px 12px;
      }
    }
  }
}

.div__ApplicationInfo {
  position: relative;
  padding-top: 7.375rem;
  margin-bottom: 16.75rem;
  @include media-breakpoint-down(md) {
    padding-top: 0rem;
    margin-bottom: 0rem;
  }
  width: 100%;
}

.container__img__ApplicationInfo {
  position: absolute;
  top: 0px;
  right: 0px;
}
