.container__ModalLoading {
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 159, 171, 0.1) 0%,
    rgba(0, 159, 171, 0) 100%
  );
  backdrop-filter: blur(15px);
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  & img {
    animation: rotation 2s infinite linear;
    width: 200px;
    height: 200px;
  }
}

.load{
  display: none;
  position: absolute;
}

.loading{
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  animation: rotation 2s infinite linear;
  width: 30vh;
  height: 30vh;
}