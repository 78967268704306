@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.containerContentsComponent {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 17px;
  width: 890px;
  height: 608px;
  gap: 20px;
  padding: 40px 25px;
  display: flex;
  .container__titleSection {
    display: none;
    & h2 {
      margin: 5px 0px 0px;
    }
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  @include media-breakpoint-down(md) {
    width: calc(100vw - 32px);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .container__chapters {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 16px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .subtitleChapter {
      width: 250px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
  }
  .container__titles {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    width: 65%;
    align-items: center;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .subtitleChapter {
      width: 400px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
    .container__titlesEmpty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      height: 100%;
      & h2 {
        text-align: center;
      }
    }
  }

  .subtitleChapter {
    background: rgba(0, 159, 171, 0.8);
    //border: 1px solid #009fab;
    border-radius: 14px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: #153039;
    }
    & h2 {
      font-family: "Montserrat Bold", sans-serif;
      font-style: normal;
      font-weight: 700;
      //Fix for the font-size
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
      margin: 0;
    }
  }
}

.container__titles::-webkit-scrollbar {
  border-radius: 8px;
  background: #a6a6a6;
}

.container__titles::-webkit-scrollbar {
  width: 10px;
}

.container__titles::-webkit-scrollbar-thumb {
  background: #098896;
  border-radius: 8px;
}

.container__chapters::-webkit-scrollbar {
  border-radius: 8px;
  background: #a6a6a6;
}

.container__chapters::-webkit-scrollbar {
  width: 10px;
}

.container__chapters::-webkit-scrollbar-thumb {
  background: #098896;
  border-radius: 8px;
}
