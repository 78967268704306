@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.externalContainerSignature {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 263px);
  background: linear-gradient(
    141.78deg,
    rgba(0, 159, 171, 0.76) 22.03%,
    rgba(196, 196, 196, 0) 76.59%
  );
  .mainContainerSignature {
    padding: 31px 0px 60px;
    display: flex;
    flex-direction: column;
    max-width: 890px;
    gap: 30px;
    .container__BreadCrumbs {
      & h3 {
        color: #ffffff;
        margin-bottom: 0px;
      }
    }
  }
  .containerContents {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 17px;
    width: 890px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 608px;
    gap: 20px;
    overflow: auto;
    justify-content: center;
    padding: 20px 60px 40px;
    font-weight: bold;
    & img {
      max-width: 452px;
      @include media-breakpoint-down(md) {
        max-width: calc(100vw - 64px);
      }
    }
    @include media-breakpoint-down(md) {
      width: calc(100vw - 32px);
      padding: 20px 16px 20px;
    }
    @include media-breakpoint-down(sm) {
      height: 500px;
    }
  }
  .external__container__carouselContent {
    position: relative;
    .continer__comebackButton {
      position: absolute;
      top: 20px;
      left: 20px;
      & button {
        background: none;
        border: none;
        & span {
          font-size: 50px;
          line-height: 50px;
          color: rgba(0, 159, 171, 0.76);
        }
      }
    }
  }
}
