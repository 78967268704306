.externalBasicLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .basicLayout {
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
