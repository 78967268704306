@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__LoginAndRegister {
  background: linear-gradient(180deg, #098896 0%, rgba(9, 136, 150, 0) 100%);
  min-height: 100% !important;
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px!important;
  margin:0px !important;
}

.container__ImageLogin{
  @include media-breakpoint-down(lg) {
    width: 15px !important;
  }
}

.container__ChildrenLoginAndRegister{
  width: 100%;
  padding: 0% !important;
  @include media-breakpoint-down(md) {
    padding-top: 0px;
  }
  @include media-breakpoint-only(lg) {
    padding-top: 50px;
  }
  @include media-breakpoint-only(xl) {
    padding-top: 100px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 100px;
  }
}

