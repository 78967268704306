.firstQuickCalculation {
  background-image: linear-gradient(to top, #ffffff, #00000021);
  width: 50%;
  height: 55%;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -70%);
  border: 1px solid #0c001b54;
  border-radius: 10px;
  .firstQuickCalculation__title {
    margin-top: 5%;
    color: black;
  }
  @media (max-width: 990px) {
    width: 70%;
  }
}

.newQuickCalculation {
  width: 35%;
  height: 10%;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, 50%);
  @media (max-width: 990px) {
    width: 55%;
  }
}

