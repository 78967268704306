@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__ClassroomSection {
  padding: 60px 0px 0px;
  .TitleClassroomSection {
    background: #009fab;
  }
  .container__DescriptionAndImage {
    & > div:first-child {
      display: flex;
      align-items: center;
      margin-top: 50px;
      flex-direction: column;
      & p {
        line-height: 38px;
        font-size: 20;
        color: #565656;
        text-align: justify;
      }
      & button {
        padding: 10px 100px;
        border-radius: 30px;
        background: #009fab;
        border: none;
        & p {
          color: #ffffff;
          font-size: 20px;
          margin: 0;
        }
      }
    }
    & > div:nth-child(2) {
      & img {
        width: 800px;
        //aspect-ratio: 16/9;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }
}
