@mixin scrollbar(
  $width: 6px,
  $track-bg: rgba(61, 60, 58, 0.199),
  $thumb-border: rgb(0, 0, 0)
) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background: $track-bg;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid $thumb-border;
    border-radius: 50px;
  }
}

//*Titulo para las notas finales
.containerSubjecteviewResults {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  .subjecteview {
    margin-top: 2%;
    font-size: 18px;
    border: none;
    border-bottom: 2px solid black;
    width: 40%;
    text-align: center;
    @media (max-width: 990px) {
      font-size: 3vw;
      width: 50%;
    }
    @media (max-width: 500px) {
      font-size: 5vw;
      width: 60%;
    }
  }
}

//*Estilos para las notas finales
.finallViewNotes {
  margin-bottom: 2%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  @media (max-width: 990px) {
    height: 60%;
  }
  @include scrollbar;
  .summaryOfNotes {
    @include scrollbar;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 40%;
    .noteNoteSumary {
      margin-left: auto;
    }
    .nameNoteSumary {
      margin-right: auto;
    }
    @media (max-width: 990px) {
      width: 65%;
    }
  }
}
.SkipButton {
  font-family: 'Montserrat Bold', sans-serif;
  background: #004aad;
  border: none;
  border-radius: 10px;
  width: 33%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: white;
  margin: auto;
  margin-top: 1%;
  cursor: pointer;
  &:hover {
    background: #153039;
  }
  @media (max-width: 990px) {
    width: 50%;
    font-size: 3vw;
  }
}

.submitButton {
  font-family: 'Montserrat Bold', sans-serif;
  background: #004aad;
  border: none;
  border-radius: 10px;
  width: 33%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: white;
  margin: auto;
  margin-top: 1%;
  cursor: pointer;
  &:hover {
    background: #153039;
  }
  @media (max-width: 990px) {
    width: 50%;
    font-size: 3vw;
  }
}