.container__ChannelBar {
  background: linear-gradient(0deg, #009fab, #009fab);
  padding: 4.3125rem 0rem 6rem 0rem;
  .icon__ChannelBar {
    color: #ffffff;
    width: 40px;
    height: 40px;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 47px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 69px;
  }
}
