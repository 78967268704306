.container__subjectLetter {
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(0, 159, 171, 0.1) 0%,
    rgba(0, 159, 171, 0) 100%
  );
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  height: 100%;
  p {
    font-family: Dosis;
    font-style: normal;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 13px;
    color: #4f4f4f;
  }
  h2 {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 38px;
    color: rgba(0, 74, 173, 1);
  }
}
