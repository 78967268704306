@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat Bold", sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  font-style: normal;
  & div:nth-child(1) {
    background: #098896;
    border-radius: 16px;
    width: 100%;
    height: 20px;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  & div:nth-child(2) {
    width: 100%;
    max-width: 80%;
    padding: 10px;
    background: #098896;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      max-width: 100%;
      border-radius: 20px;
    }
    & h1 {
      font-weight: 800;
      font-size: 32px;
      line-height: 24px;
      // Centrate text in the middle of 
      margin-bottom: 10px;
      margin-top: -10px;
      @include media-breakpoint-down(md) {
        margin: 0px;
      }
    }
    & h2 {
      cursor: pointer;
      font-size: 20px;
      line-height: 24px;
      &:first-child {
        font-weight: 500;
      }
      &:last-child {
        font-weight: 300;
      }
    }
  }
}
