.modal-content .modal__ModalComingSoon {
  background: linear-gradient(0deg, #009fab, #009fab);
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  border: 0px !important;
  padding: 110px;
  width: 797px !important;
  height: 305px !important;
  position: absolute;
  right: -15%;
  top: 100px;
}

.modal-content .modal__ModalComingSoon p {
  font-family: Dosis;
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 88px;
  text-align: center;
  color: #ffffff;
}
