@font-face {
  font-family: "Dosis";
  src: local("Dosis"), url(./fonts/Dosis/Dosis-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
