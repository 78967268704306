@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__PomodoroSection {
  width: 100%;
  display: flex;
  justify-content: center;
  .TitlePomodoro {
    background-color: #264f80;
  }
  .contianer__pomodoro_Icon {
    cursor: pointer;
  }
  .container__titleIcon {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
