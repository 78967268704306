.container__BenefitsCard {
  padding: 25px 12px 25px 12px;
  background: #ffffff;
  border: 4px solid #098896;
  box-sizing: border-box;
  border-radius: 25px;
  min-height: 100%;
  width: 100% !important;
  h6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #098896;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 18px 0px;
  }
  .p__BenefitsCard {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 18px 0px;
    color: rgba(166, 166, 166, 1);
  }
}
