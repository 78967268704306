.mainContainer__ScheduleTask {
  position: relative;
  .cards_Container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 25px;
  }
  .container__addPomodoroIcon {
    position: absolute;
    bottom: -150px;
    right: -150px;
    button {
      background: none;
      border: none;
    }
  }
}
