.container__SubjectLetterProfile {
  background: white;
  // background: linear-gradient(
  //   180deg,
  //   rgba(0, 159, 171, 0.1) 0%,
  //   rgba(0, 159, 171, 0) 100%
  // );
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.15);
  border-radius: 28px;
  margin: 15px;
  width: 281px;
  height: 282px;
  padding: 25px;
  font-family: "Dosis";
  font-style: normal;
  h5 {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: rgba(0, 74, 173, 1);
  }
  a {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: rgba(0, 74, 173, 1);
  }
}
