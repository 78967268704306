@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.container__Header {
  position: relative;
  img {
    margin-left: -5px;
  }
  a.nav-link {
    text-decoration: none;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba(0, 159, 171, 1) !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  h6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba(0, 159, 171, 1);
    margin-bottom: 0px;
  }
  .button__Login {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border-radius: 20px;
    border: 1px solid rgba(0, 159, 171, 1);
    background-color: transparent;
    height: 39px;
    color: rgba(0, 159, 171, 1);
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      width: fit-content;
    }
  }
}

.container__HeaderShadow {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
}

.container__CoverShadow {
  position: absolute;
  right: 0px;
  top: 550%;
}
